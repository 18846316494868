import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class PolygonPetalingJayaProvider {

    items: any;
 
    constructor() {

        this.items = [
            {lng: 101.582164800000001, lat: 3.2052959},
            {lng: 101.582573499999995, lat: 3.205178},
            {lng: 101.584090500000002, lat: 3.2043035},
            {lng: 101.585816500000007, lat: 3.2033087},
            {lng: 101.586966500000003, lat: 3.2026989},
            {lng: 101.588277000000005, lat: 3.2022042},
            {lng: 101.590161100000003, lat: 3.2016934},
            {lng: 101.594795000000005, lat: 3.2003723},
            {lng: 101.5958179, lat: 3.2003242},
            {lng: 101.599743099999998, lat: 3.2009985},
            {lng: 101.600647600000002, lat: 3.2009983},
            {lng: 101.601856699999999, lat: 3.2008206},
            {lng: 101.604068600000005, lat: 3.1998102},
            {lng: 101.605874499999999, lat: 3.199447},
            {lng: 101.607385699999995, lat: 3.1994899},
            {lng: 101.608735999999993, lat: 3.1997841},
            {lng: 101.610054599999998, lat: 3.2003454},
            {lng: 101.610988000000006, lat: 3.2009345},
            {lng: 101.612215599999999, lat: 3.2020203},
            {lng: 101.613952600000005, lat: 3.2038603},
            {lng: 101.615957699999996, lat: 3.205957},
            {lng: 101.616820300000001, lat: 3.2068769},
            {lng: 101.617843399999998, lat: 3.2077267},
            {lng: 101.618519300000003, lat: 3.2080374},
            {lng: 101.619386599999999, lat: 3.2081496},
            {lng: 101.626270599999998, lat: 3.2085507},
            {lng: 101.627035500000005, lat: 3.2085826},
            {lng: 101.628408500000006, lat: 3.2085505},
            {lng: 101.628947699999998, lat: 3.2084166},
            {lng: 101.629330800000005, lat: 3.2083997},
            {lng: 101.627624499999996, lat: 3.2036909},
            {lng: 101.626204900000005, lat: 3.2011251},
            {lng: 101.625165600000003, lat: 3.1995411},
            {lng: 101.623921100000004, lat: 3.198324},
            {lng: 101.623034500000003, lat: 3.197846},
            {lng: 101.621898700000003, lat: 3.1975594},
            {lng: 101.620247800000001, lat: 3.1974894},
            {lng: 101.619116500000004, lat: 3.1979053},
            {lng: 101.618848600000007, lat: 3.1962285},
            {lng: 101.618858900000006, lat: 3.195483},
            {lng: 101.619020399999997, lat: 3.1938579},
            {lng: 101.619613799999996, lat: 3.1901749},
            {lng: 101.619884799999994, lat: 3.1883939},
            {lng: 101.619557, lat: 3.1857576},
            {lng: 101.619168099999996, lat: 3.1821202},
            {lng: 101.619304, lat: 3.178065},
            {lng: 101.619040499999997, lat: 3.1740359},
            {lng: 101.618942000000004, lat: 3.1733769},
            {lng: 101.617970400000004, lat: 3.1722066},
            {lng: 101.617815199999995, lat: 3.1710006},
            {lng: 101.616759700000003, lat: 3.1701578},
            {lng: 101.616645899999995, lat: 3.168471},
            {lng: 101.616533799999999, lat: 3.1675584},
            {lng: 101.616183399999997, lat: 3.1657988},
            {lng: 101.616050099999995, lat: 3.1644449},
            {lng: 101.616553600000003, lat: 3.1633401},
            {lng: 101.616360099999994, lat: 3.161347},
            {lng: 101.615616900000006, lat: 3.1607388},
            {lng: 101.615267700000004, lat: 3.158678},
            {lng: 101.615081000000004, lat: 3.1534803},
            {lng: 101.615178499999999, lat: 3.152866},
            {lng: 101.616490499999998, lat: 3.1506222},
            {lng: 101.617380800000007, lat: 3.1492228},
            {lng: 101.618721100000002, lat: 3.1469526},
            {lng: 101.619300499999994, lat: 3.1460884},
            {lng: 101.620025499999997, lat: 3.144747},
            {lng: 101.621323099999998, lat: 3.1433364},
            {lng: 101.623323299999996, lat: 3.1413027},
            {lng: 101.624835899999994, lat: 3.1415503},
            {lng: 101.625321799999995, lat: 3.1415494},
            {lng: 101.626156499999993, lat: 3.1409386},
            {lng: 101.627154399999995, lat: 3.1394086},
            {lng: 101.628068999999996, lat: 3.138012},
            {lng: 101.629103999999998, lat: 3.1373507},
            {lng: 101.629600699999997, lat: 3.136808},
            {lng: 101.629621400000005, lat: 3.1351764},
            {lng: 101.629378099999997, lat: 3.1339877},
            {lng: 101.629222499999997, lat: 3.133422},
            {lng: 101.629274600000002, lat: 3.1326296},
            {lng: 101.629499199999998, lat: 3.1305895},
            {lng: 101.630003099999996, lat: 3.1306056},
            {lng: 101.630369000000002, lat: 3.1306962},
            {lng: 101.631708099999997, lat: 3.1316348},
            {lng: 101.634873400000004, lat: 3.1323485},
            {lng: 101.636119699999995, lat: 3.1320682},
            {lng: 101.636621599999998, lat: 3.1318186},
            {lng: 101.637160199999997, lat: 3.131462},
            {lng: 101.638544600000003, lat: 3.1303833},
            {lng: 101.640389499999998, lat: 3.1295851},
            {lng: 101.643771299999997, lat: 3.1277758},
            {lng: 101.644691199999997, lat: 3.1275108},
            {lng: 101.645726199999999, lat: 3.1275397},
            {lng: 101.646434099999993, lat: 3.1277216},
            {lng: 101.647049100000004, lat: 3.1282235},
            {lng: 101.647882999999993, lat: 3.1272514},
            {lng: 101.648137300000002, lat: 3.126654},
            {lng: 101.648361499999993, lat: 3.1260753},
            {lng: 101.648106600000006, lat: 3.1250671},
            {lng: 101.644106500000007, lat: 3.1208351},
            {lng: 101.643637299999995, lat: 3.1203716},
            {lng: 101.643496499999998, lat: 3.1201506},
            {lng: 101.643444200000005, lat: 3.1199364},
            {lng: 101.643424100000004, lat: 3.1196016},
            {lng: 101.643411999999998, lat: 3.1192253},
            {lng: 101.643464300000005, lat: 3.1190632},
            {lng: 101.643586299999996, lat: 3.1188731},
            {lng: 101.643711600000003, lat: 3.1187534},
            {lng: 101.643838500000001, lat: 3.1186575},
            {lng: 101.643972599999998, lat: 3.118612},
            {lng: 101.645306899999994, lat: 3.1185693},
            {lng: 101.6473941, lat: 3.1184553},
            {lng: 101.647887800000007, lat: 3.1183574},
            {lng: 101.648456400000001, lat: 3.1181753},
            {lng: 101.648864099999997, lat: 3.1179932},
            {lng: 101.649207399999995, lat: 3.1177468},
            {lng: 101.649486400000001, lat: 3.1174683},
            {lng: 101.649840400000002, lat: 3.1170183},
            {lng: 101.650140800000003, lat: 3.1165041},
            {lng: 101.650527100000005, lat: 3.1157863},
            {lng: 101.650881100000007, lat: 3.11494},
            {lng: 101.652672800000005, lat: 3.1101406},
            {lng: 101.653188099999994, lat: 3.1104568},
            {lng: 101.658500399999994, lat: 3.1137154},
            {lng: 101.659690600000005, lat: 3.1145888},
            {lng: 101.659689, lat: 3.1135419},
            {lng: 101.659826800000005, lat: 3.1073154},
            {lng: 101.659918599999997, lat: 3.1040359},
            {lng: 101.660389499999994, lat: 3.0999536},
            {lng: 101.6595741, lat: 3.0988413},
            {lng: 101.659953099999996, lat: 3.0973736},
            {lng: 101.659792300000007, lat: 3.0957109},
            {lng: 101.660026599999995, lat: 3.0919161},
            {lng: 101.660733699999994, lat: 3.0909607},
            {lng: 101.663188099999999, lat: 3.087721},
            {lng: 101.663616599999997, lat: 3.0863646},
            {lng: 101.663808399999994, lat: 3.085849},
            {lng: 101.659847900000003, lat: 3.0844879},
            {lng: 101.660373100000001, lat: 3.0832446},
            {lng: 101.659471300000007, lat: 3.082867},
            {lng: 101.660163800000007, lat: 3.0811182},
            {lng: 101.658973399999994, lat: 3.0806229},
            {lng: 101.659169399999996, lat: 3.0801685},
            {lng: 101.659247100000002, lat: 3.0799778},
            {lng: 101.659276000000006, lat: 3.0798196},
            {lng: 101.658901400000005, lat: 3.0795659},
            {lng: 101.659081400000005, lat: 3.0790415},
            {lng: 101.657543500000003, lat: 3.0784848},
            {lng: 101.657437700000003, lat: 3.0786884},
            {lng: 101.657312599999997, lat: 3.0787017},
            {lng: 101.657074499999993, lat: 3.0786298},
            {lng: 101.655424400000001, lat: 3.078617},
            {lng: 101.654613800000007, lat: 3.0783374},
            {lng: 101.654013500000005, lat: 3.0776378},
            {lng: 101.654104200000006, lat: 3.0769934},
            {lng: 101.652047999999994, lat: 3.0763907},
            {lng: 101.651616700000005, lat: 3.0752235},
            {lng: 101.651503399999996, lat: 3.0750706},
            {lng: 101.6513633, lat: 3.0749177},
            {lng: 101.651083099999994, lat: 3.0746493},
            {lng: 101.650560299999995, lat: 3.0741876},
            {lng: 101.647454499999995, lat: 3.0722223},
            {lng: 101.646649100000005, lat: 3.071786},
            {lng: 101.646424999999994, lat: 3.0716645},
            {lng: 101.646568099999996, lat: 3.0713969},
            {lng: 101.646328400000002, lat: 3.0714381},
            {lng: 101.645898500000001, lat: 3.07149},
            {lng: 101.645543399999994, lat: 3.0714553},
            {lng: 101.642312200000006, lat: 3.0711938},
            {lng: 101.638408999999996, lat: 3.0700568},
            {lng: 101.637945599999995, lat: 3.0699623},
            {lng: 101.637319899999994, lat: 3.0699499},
            {lng: 101.629129000000006, lat: 3.0709693},
            {lng: 101.626861199999993, lat: 3.072091},
            {lng: 101.624793299999993, lat: 3.0734215},
            {lng: 101.624226300000004, lat: 3.0736216},
            {lng: 101.623464999999996, lat: 3.0737675},
            {lng: 101.622845600000005, lat: 3.0737857},
            {lng: 101.622224000000003, lat: 3.0734502},
            {lng: 101.621451199999996, lat: 3.0731546},
            {lng: 101.620863700000001, lat: 3.0723792},
            {lng: 101.617158099999997, lat: 3.0723021},
            {lng: 101.617159099999995, lat: 3.0745139},
            {lng: 101.616622500000005, lat: 3.0744342},
            {lng: 101.614209599999995, lat: 3.0743539},
            {lng: 101.612939999999995, lat: 3.0743111},
            {lng: 101.610020700000007, lat: 3.0738748},
            {lng: 101.606395000000006, lat: 3.0737321},
            {lng: 101.604253700000001, lat: 3.0732813},
            {lng: 101.602041900000003, lat: 3.0729468},
            {lng: 101.598954500000005, lat: 3.0729121},
            {lng: 101.598335199999994, lat: 3.0729014},
            {lng: 101.598270600000006, lat: 3.0731801},
            {lng: 101.597670500000007, lat: 3.0756154},
            {lng: 101.5977563, lat: 3.0757975},
            {lng: 101.598035300000006, lat: 3.0761297},
            {lng: 101.598239100000001, lat: 3.0761511},
            {lng: 101.601180299999996, lat: 3.076391},
            {lng: 101.601307599999998, lat: 3.0764296},
            {lng: 101.602466300000003, lat: 3.0771046},
            {lng: 101.602584300000004, lat: 3.0772653},
            {lng: 101.605041299999996, lat: 3.0822109},
            {lng: 101.605141399999994, lat: 3.0824179},
            {lng: 101.605107899999993, lat: 3.0826127},
            {lng: 101.600347999999997, lat: 3.082608},
            {lng: 101.599269699999994, lat: 3.08259},
            {lng: 101.593545599999999, lat: 3.0840584},
            {lng: 101.591352799999996, lat: 3.0843465},
            {lng: 101.590764399999998, lat: 3.0843352},
            {lng: 101.591240299999996, lat: 3.0882243},
            {lng: 101.591394100000002, lat: 3.0929496},
            {lng: 101.589820000000003, lat: 3.0973983},
            {lng: 101.588605999999999, lat: 3.1002362},
            {lng: 101.587333900000004, lat: 3.1033646},
            {lng: 101.586602200000002, lat: 3.1041215},
            {lng: 101.585608300000004, lat: 3.1066187},
            {lng: 101.5851392, lat: 3.1072441},
            {lng: 101.584538699999996, lat: 3.1078124},
            {lng: 101.584411299999999, lat: 3.1078187},
            {lng: 101.584144699999996, lat: 3.1080742},
            {lng: 101.582274600000005, lat: 3.1087939},
            {lng: 101.580528900000004, lat: 3.10943},
            {lng: 101.577366600000005, lat: 3.1105138},
            {lng: 101.577156200000005, lat: 3.1105854},
            {lng: 101.575869499999996, lat: 3.1111139},
            {lng: 101.569619299999999, lat: 3.1135691},
            {lng: 101.566566899999998, lat: 3.1147697},
            {lng: 101.566308399999997, lat: 3.1148587},
            {lng: 101.567237500000005, lat: 3.1161338},
            {lng: 101.570357700000002, lat: 3.1162263},
            {lng: 101.570158300000003, lat: 3.1198117},
            {lng: 101.570156600000004, lat: 3.124809},
            {lng: 101.568488400000007, lat: 3.1249171},
            {lng: 101.568788699999999, lat: 3.1250829},
            {lng: 101.568940799999993, lat: 3.1254854},
            {lng: 101.568894, lat: 3.1258265},
            {lng: 101.568764099999996, lat: 3.1260984},
            {lng: 101.568679799999998, lat: 3.1264062},
            {lng: 101.5685754, lat: 3.126717},
            {lng: 101.568549399999995, lat: 3.1267943},
            {lng: 101.568511799999996, lat: 3.1272781},
            {lng: 101.568468800000005, lat: 3.1276269},
            {lng: 101.568392399999993, lat: 3.1284487},
            {lng: 101.5684258, lat: 3.1286894},
            {lng: 101.568626499999993, lat: 3.1294713},
            {lng: 101.568519499999994, lat: 3.1301403},
            {lng: 101.5685395, lat: 3.1304279},
            {lng: 101.568486100000001, lat: 3.1305683},
            {lng: 101.568344999999994, lat: 3.1306892},
            {lng: 101.568217099999998, lat: 3.1315148},
            {lng: 101.567898700000001, lat: 3.1317301},
            {lng: 101.567368200000004, lat: 3.1321182},
            {lng: 101.5665963, lat: 3.132447},
            {lng: 101.565979400000003, lat: 3.1325702},
            {lng: 101.563744700000001, lat: 3.1325276},
            {lng: 101.562403599999996, lat: 3.1322169},
            {lng: 101.558764499999995, lat: 3.1322917},
            {lng: 101.557941799999995, lat: 3.1324496},
            {lng: 101.557448300000004, lat: 3.1327388},
            {lng: 101.556995900000004, lat: 3.133345},
            {lng: 101.556813500000004, lat: 3.133827},
            {lng: 101.556308299999998, lat: 3.134217},
            {lng: 101.555315899999997, lat: 3.1350678},
            {lng: 101.553032200000004, lat: 3.1367472},
            {lng: 101.552475099999995, lat: 3.1371907},
            {lng: 101.552330600000005, lat: 3.1373068},
            {lng: 101.551740600000002, lat: 3.1404378},
            {lng: 101.550445199999999, lat: 3.1476152},
            {lng: 101.550867299999993, lat: 3.1477075},
            {lng: 101.551790499999996, lat: 3.147437},
            {lng: 101.552365499999993, lat: 3.149222},
            {lng: 101.553349299999994, lat: 3.1519426},
            {lng: 101.553998100000001, lat: 3.1538114},
            {lng: 101.555812000000003, lat: 3.1539247},
            {lng: 101.556738699999997, lat: 3.1541597},
            {lng: 101.558523699999995, lat: 3.1548177},
            {lng: 101.559414200000006, lat: 3.1552569},
            {lng: 101.562040600000003, lat: 3.156824},
            {lng: 101.563117800000001, lat: 3.1575888},
            {lng: 101.564039800000003, lat: 3.1585675},
            {lng: 101.564865100000006, lat: 3.1600551},
            {lng: 101.569206100000002, lat: 3.1679182},
            {lng: 101.570589699999999, lat: 3.1701374},
            {lng: 101.5732541, lat: 3.1744328},
            {lng: 101.574610199999995, lat: 3.1764855},
            {lng: 101.575307600000002, lat: 3.1770853},
            {lng: 101.576754399999999, lat: 3.1784491},
            {lng: 101.577424500000006, lat: 3.1794548},
            {lng: 101.577965899999995, lat: 3.1805085},
            {lng: 101.578550199999995, lat: 3.182595},
            {lng: 101.578919799999994, lat: 3.183792},
            {lng: 101.579563100000001, lat: 3.1854554},
            {lng: 101.579541599999999, lat: 3.1858303},
            {lng: 101.579391599999994, lat: 3.1870389},
            {lng: 101.579144799999995, lat: 3.1875959},
            {lng: 101.5787859, lat: 3.1881408},
            {lng: 101.578592999999998, lat: 3.1888359},
            {lng: 101.578502499999999, lat: 3.1891884},
            {lng: 101.578652599999998, lat: 3.1896805},
            {lng: 101.579043200000001, lat: 3.1909674},
            {lng: 101.578304900000006, lat: 3.1934918},
            {lng: 101.578014499999995, lat: 3.195273},
            {lng: 101.578346800000006, lat: 3.1973686},
            {lng: 101.578915300000006, lat: 3.2002528},
            {lng: 101.579059999999998, lat: 3.2012099},
            {lng: 101.5798372, lat: 3.2047985},
            {lng: 101.579788800000003, lat: 3.2060114},
            {lng: 101.580641099999994, lat: 3.2057422},
            {lng: 101.581730199999996, lat: 3.2054349},
            {lng: 101.582164800000001, lat: 3.2052959}
        ]
    }

    getPolygonInner() {
        return this.items;
    }
}
