import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//import 'rxjs/Rx';
import { AppDataProvider } from '../../providers/AppDataProvider';


@Injectable()
export class NotificationService {
    http: any;
    apiURL: string;

    constructor(http:HttpClient, public appDataProvider: AppDataProvider) {
        this.http = http;
        this.apiURL = this.appDataProvider.getDefault();
    }

    getunreadNotificationCountByUserId(apiToken, id) {
        //  return this.http.get(this.apiURL + 'api/notification/GetUnreadNotificationCountByUserId?UserId=' + id)
        //     .map(res => res.json());
        let url=this.apiURL + 'api/notification/GetUnreadNotificationCountByUserId?UserId=' + id;
        return  this.http.get(url);
    }

    notificationsPaginateListByUserId(apiToken, id, page) {
        //return this.http.get(this.apiURL + 'api/notificationsPaginateListByUserId/' + id + '?api_token=' + apiToken + '&page=' + page)
        return this.http.get(this.apiURL + 'api/notification/GetNotificationsByUserId?UserId=' + id)
            .map(res => res.json());
    }
    
    notificationsUserRemove(apiToken, id) {
        return this.http.post(this.apiURL + 'api/notificationsUserRemove?api_token=' + apiToken + '&id=' + id)
            .map(res => res.json());
    }

    notificationReadWithComplaintsById(apiToken, id, notificationId, complaintId) {
        //return this.http.post(this.apiURL + 'api/notification/notificationReadWithComplaintsFullById?api_token=' + apiToken + '&id=' + id + '&notificationId=' + notificationId)
        return this.http.post(this.apiURL + 'api/notification/notificationReadWithComplaintsById?complaintId=' + complaintId)
            .map(res => res.json());
    }

}