import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//import 'rxjs/Rx';
import { AppDataProvider } from '../../providers/AppDataProvider';


@Injectable()
export class ComplaintService {
    http: any;
    baseUrl: string;
    data: string;
    response: string;
    apiURL: string;
    //apiToken: string = 'DX3LbGOYl0y6YSmKkLweuitQpz9iebFDwZ46nOx6HIlrw1cKIesTzS9X7dEL';

    constructor(http:HttpClient, public appDataProvider: AppDataProvider) {
        this.http = http;
        this.apiURL = this.appDataProvider.getDefault();
    }

    fullComplaint(apiToken) {
        return this.http.get(this.apiURL + 'api/complaintsFull?api_token=' + apiToken)
            .map(res => res.json());
    }

    submitFullComplaint(
        apiToken,
        category_id,
        subcategory_id,
        title,
        content,
        address,
        latitude,
        longitude,
        overlaps,
        create_by,
        picture1,
        picture2,
        picture3
    ) {
        return this.http.post(this.apiURL + 'api/complaintsFull?api_token=' + apiToken
            + '&category_id=' + category_id
            + '&subcategory_id=' + subcategory_id
            + '&title=' + title
            + '&content=' + content
            + '&address=' + address
            + '&latitude=' + latitude
            + '&longitude=' + longitude
            + '&overlaps=' + overlaps
            + '&create_by=' + create_by
            + '&picture1=' + picture1
            + '&picture2=' + picture2
            + '&picture3=' + picture3)
            .map(res => res.json());
    }

    checkComplaintStatus(apiToken, complaintNo) {
        return this.http.get(this.apiURL + 'api/checkComplaintStatus/' + complaintNo + '?api_token=' + apiToken)
            .map(res => res.json());
    }

    complaintOverlapByCoordinate(apiToken, latitude, longitude, category, subcategory) {
        //return this.http.post(this.apiURL + 'api/complaintOverlapByCoordinate?api_token=' + apiToken + '&latitude=' + latitude + '&longitude=' + longitude + '&category=' + category + '&subcategory=' + subcategory)
        return this.http.post(this.apiURL + 'api/Complaint/Save?api_token=' + apiToken)
            .map(res => res.json());
    }

    complaintsFullByUserIdSorting(apiToken, id) {
        return this.http.get(this.apiURL + 'api/complaintsFullByUserIdSorting/' + id + '?api_token=' + apiToken)
            .map(res => res.json());
    }

    complaintsFullListByUserId(apiToken, id) {
        //return this.http.get(this.apiURL + 'api/complaintsFullListByUserId/' + id + '?api_token=' + apiToken)
        return this.http.get(this.apiURL + 'api/Complaint/GetComplaintListByUserId?UserId=' + id)
            .map(res => res.json());
    }

    complaintsMapFullListByUserId(apiToken, id) {
        //return this.http.get(this.apiURL + 'api/complaintsFullListByUserId/' + id + '?api_token=' + apiToken)
        return this.http.get(this.apiURL + 'api/Complaint/GetMapComplaintListByUserId?UserId=' + id)
            .map(res => res.json());
    }

    complaintsPaginateListByUserId(apiToken, id) {
        //return this.http.get(this.apiURL + 'api/complaintsPaginateListByUserId/' + id + '?api_token=' + apiToken + '&page=' + page)
        return this.http.get(this.apiURL + 'api/Complaint/GetMapComplaintListByUserId?UserId=' + id)
            .map(res => res.json());
    }

    // fullComplaintsById(apiToken, id) {
    //     return this.http.get(this.apiURL + 'api/complaintsFullById/' + id + '?api_token=' + apiToken)
    //         .map(res => res.json());
    // }
    fullComplaintsById(id) {
        return this.http.get(this.apiURL + 'api/complaint/GetComplaintById?id=' + id)
            .map(res => res.json());
    }

    verifyStacked(complaint) {
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.apiURL + 'api/complaint/VerifyStacked', JSON.stringify(complaint), options)
            .map(res => res.json());
    }
    
    submitComplaint(complaint) {
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.apiURL + 'api/complaint/MSave', JSON.stringify(complaint), options)
            .map(res => res.json());      
    }

    upload(fullComplaintNo, complaintId) {
        //return this.http.get(this.apiURL + 'api/complaintsFullListByUserId/' + id + '?api_token=' + apiToken)
        return this.http.post(this.apiURL + 'api/attachment/uploadMobile?fullComplaintNo=' + fullComplaintNo + '&complaintId=' + complaintId)
            .map(res => res.json());
    }

}