import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//import 'rxjs/Rx';
import { AppDataProvider } from '../../providers/AppDataProvider';

@Injectable()
export class ProfileService {
    http: any;
    response: string;
    apiURL: string;
    idsURL: string;

    constructor(http:HttpClient, public appDataProvider: AppDataProvider) {
        this.http = http;
        this.apiURL = this.appDataProvider.getDefault();
    }

    getProfile(userID, apiToken){
        return this.http.get(this.apiURL + 'api/user/MGetUserInfo?id=' + userID);
            //.map(res => res.json());
    }

    setProfile(userID, apiToken, user){
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.apiURL + 'api/user/MupdateUser', JSON.stringify(user), options)
            .map(res => res.json());
    }

    setPassword(userID, apiToken, model){
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.idsURL + 'api/security/ChangePassword', JSON.stringify(model), options)
            .map(res => res.json());
    }
}