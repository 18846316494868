import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//import 'rxjs/Rx';
//import 'rxjs/add/operator/map';
import { Observable } from  'rxjs/Observable';
import  'rxjs/add/operator/catch';
import  'rxjs/add/operator/map';

import { AppDataProvider } from '../../providers/AppDataProvider';


@Injectable()
export class AuthService {
    http: any;
    baseUrl: string;
    data: string;
    response: string;
    apiURL: string;
    idsURL: string;

    constructor(http:HttpClient, public appDataProvider: AppDataProvider) {
        this.apiURL = this.appDataProvider.getDefault();
        this.idsURL = this.appDataProvider.getIDSUrl();
        this.http = http;
    }

    login(email, password) {
        return this.http.post(this.apiURL + 'api/auth/login?email=' + email + '&password=' + password,null)
            .map(res => res.toString());
    }

    // register(username, name, email, password, mobile, ic, address) {
    //     return this.http.post(this.apiURL + 'api/MobileAccount/register?' +
    //         '&username=' + username + 
    //         '&first_name=' + name + 
    //         '&last_name=' + '' +
    //         '&email=' + email + 
    //         '&password=' + password + 
    //         '&mobile=' + mobile + 
    //         '&ic=' + ic + 
    //         '&address=' + address + 
    //         '&user_type=' + 'user' + 
    //         '&picture=' + '' + 
    //         '&is_verified=' + '0' + 
    //         '&unit_id=' + '0' + 
    //         '&department_id=' + '1')
    //             .map(res => res.json());
    // }
    register(user) {
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.idsURL + 'api/security/register', JSON.stringify(user), options)
        .map(res => res.json());           
    }

    forgotPassword(user) {
        const options = {headers: {'Content-Type': 'application/json'}};
        return this.http.post(this.idsURL + 'api/security/forgotPassword', JSON.stringify(user), options)
        .map(res => res.json());             
    }

    getFirstTime(email) {

        let url=this.apiURL + 'api/auth/getFirstTime?Email=' + email;
        return  this.http.post(url);
        // return new Promise((resolve, reject) => {
        //     this.http.post(url).subscribe((response: any) => {
        //       console.log(response);
        //       resolve(response);
        //       //return response;
        //     }, (error: any) => { reject(error); });
        //   });

        // return this.http.post(this.apiURL + 'api/auth/getFirstTime?Email=' + email)
        // .subscribe(data => {
        //     console.log(data);
        //  });
    }

    updateFirstTime(email) {
        return this.http.post(this.apiURL + 'api/user/MupdateFirstTime?email=' + email,null);           
    }

    logout(api_token) {
        return this.http.post(this.apiURL + 'api/auth/logout',null)
            .map(res => res.json());
            // return this.http.post(this.apiURL + 'api/auth/logout?api_token=' + api_token)
            // .map(res => res.json());
    }
    // logout(api_token) {
    //     return this.http.post(this.apiURL + 'api/logout?api_token=' + api_token, '')
    //         .map(res => res.json());
    // }

    checker(api_token) {
        return this.http.post(this.apiURL + 'api/checker?api_token=' + api_token,null)
            .map(res => res.json());
    }
    
}