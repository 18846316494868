import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'camera',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'rekod-aduan-list',
    loadChildren: () => import('./pages/rekod-aduan-list/rekod-aduan-list.module').then(m => m.RekodAduanListPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'tutorial-menu',
    loadChildren: () => import('./pages/tutorial-menu/tutorial-menu.module').then(m => m.TutorialMenuPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'sub-category',
    loadChildren: () => import('./pages/sub-category/sub-category.module').then(m => m.SubCategoryPageModule)
  },
  {
    path: 'upload-photo',
    loadChildren: () => import('./pages/upload-photo/upload-photo.module').then(m => m.UploadPhotoPageModule)
  },
  {
    path: 'aduan-bertindan',
    loadChildren: () => import('./pages/aduan-bertindan/aduan-bertindan.module').then(m => m.AduanBertindanPageModule)
  },
  {
    path: 'pengesahan',
    loadChildren: () => import('./pages/pengesahan/pengesahan.module').then(m => m.PengesahanPageModule)
  },
  {
    path: 'camera',
    loadChildren: () => import('./pages/camera/camera.module').then(m => m.CameraPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
