import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//import { IonicImageViewerModule } from 'ionic-img-viewer';

//import { Storage, IonicStorageModule } from '@ionic/storage';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import { Keyboard } from '@ionic-native/keyboard/ngx';

import { AuthService } from './services/auth/auth.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { NotificationService } from './services/notification/notification.service';
import { ProfileService } from './services/profile/profile.service';
import {GoogleMapsModule} from '@angular/google-maps';
import { PolygonPetalingJayaProvider } from './providers/polygon/PolygonPetalingJayaProvider';
import { CategoryService } from './services/category/category.service';
import { ComplaintService } from './services/complaint/complaint.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    //IonicImageViewerModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthService,
    Keyboard,
    Storage,
    OneSignal,
    NotificationService,
    ProfileService,
    CategoryService,
    ComplaintService,
    ImagePicker,
    PolygonPetalingJayaProvider,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
