import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//import 'rxjs/Rx';
import { AppDataProvider } from '../../providers/AppDataProvider';


@Injectable()
export class CategoryService {
    http: any;
    baseUrl: string;
    data: string;
    response: string;
    apiURL: string;

    constructor(http:HttpClient, public appDataProvider: AppDataProvider) {
        this.http = http;
        this.apiURL = this.appDataProvider.getDefault();
    }

    category(apiToken) {
        //return this.http.get(this.apiURL + 'api/categories?api_token=' + apiToken)
        return this.http.get(this.apiURL + 'api/grouptype/get')
            .map(res => res.json());
    }

    fullCategory(apiToken) {
        //return this.http.get(this.apiURL + 'api/categoriesFull?api_token=' + apiToken)
        return this.http.get(this.apiURL + 'api/grouptype/MGet');
    }

    fullSubCategory(apiToken, grouptypeId) {
        //return this.http.get(this.apiURL + 'api/categoriesFull?api_token=' + apiToken)
        return this.http.get(this.apiURL + 'api/grouptype/TypeOfComplaints?id=' + grouptypeId);          
    }

}