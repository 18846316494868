import { Injectable } from '@angular/core';
//import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage,IonicStorageModule } from '@ionic/storage';

// LIVE SETTINGS
const API_URL = 'https://eaduanapi.mbpj.gov.my/';
 const IDS_URL = 'https://embpj.mbpj.gov.my/';

// STAGING SETTINGS
//const API_URL = 'https://eaduanmobile.mbpj.gov.my/';
//const IDS_URL = 'https://embpjdev.mbpj.gov.my/';

// DEV SETTINGS
// const API_URL = 'https://localhost:44300/';
// const IDS_URL = 'https://localhost:44302/';


const API_TOKEN = 'apiToken';
const USER_ID = 'user_id';
const USER_NAME = 'user_name';
const USER_TYPE = 'user_type';
const USER_EMAIL = 'user_email';
const USER_PICTURE = 'user_picture';
const USER_USERNAME = 'user_username';
const USER_UNIT_ID = 'user_unit_id';
const USER_DEPARTMENT_ID = 'user_department_id';
const NAVIGATE_REMEMBER = 'navigate_remember';
const FIRST_TIME = 'first_time';

@Injectable({
  providedIn: 'root'
})
export class AppDataProvider {

  constructor(public storage: Storage) {


  }

  getDefault() {
    return API_URL;
  }

  getIDSUrl() {
    return IDS_URL;
  }


   // all data
   setCurrentUser(api_key, user_id, user_email, user_username, user_name, user_picture, user_type, user_department_id, user_unit_id) {
    this.storage.set(USER_ID, user_id);
    this.storage.set(USER_EMAIL, user_email);
    this.storage.set(USER_USERNAME, user_username);
    this.storage.set(USER_NAME, user_name);
    this.storage.set(USER_PICTURE, user_picture);
    this.storage.set(USER_TYPE, user_type);
    this.storage.set(USER_DEPARTMENT_ID, user_department_id);
    this.storage.set(USER_UNIT_ID, user_unit_id);
    return this.storage.set(API_TOKEN, api_key);
}

setFirstTime(first_time) {
    return this.storage.set(FIRST_TIME, first_time);
}

setNavigate(navigate_remember) {
    return this.storage.set(NAVIGATE_REMEMBER, navigate_remember);
}

removeCurrentUser() {
    this.storage.remove(USER_ID);
    this.storage.remove(USER_EMAIL);
    this.storage.remove(USER_USERNAME);
    this.storage.remove(USER_NAME);
    this.storage.remove(USER_PICTURE);
    this.storage.remove(USER_TYPE);
    this.storage.remove(USER_DEPARTMENT_ID);
    this.storage.remove(USER_UNIT_ID);
    this.storage.remove(NAVIGATE_REMEMBER);
    return this.storage.remove(API_TOKEN);
}
}
